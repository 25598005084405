import { createI18n } from "vue-i18n";
import English from './locales/en/translations.json';
import Spanish from './locales/es/translations.json';

const lang = localStorage.getItem("Lang");

const i18n = createI18n({
    globalInjection: true,
    fallbackLocale: 'en',
	locale: lang ?? 'es',
    messages: {
        en: English,
        es: Spanish,
      }
});

export default i18n;